import React, { useEffect, useState } from "react";
import ScrollToTop from "../../../ScrollToTop";
import NavbarDashboard from "../../NavbarDashboard";
import { useHistory, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import {
  getInspectionBookmark,
  getInspectionStepsApi,
  storeActiveInspectionStep,
} from "../../../../redux/reducers/inspectionSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import DialogBox from "../../../../utils/DialogBox";

const InspectionSteps = () => {
  const history = useHistory();
  var token = localStorage.getItem("access_token");
  const { id, clientId } = useParams();
  const dispatch = useDispatch();
  const [showVeiwPopup, setShowVeiwPopup] = useState(false);
  const [description, setDescription] = useState("");
  const [dialogBoxStatus, setDialogBoxStatus] = useState("");
  const [tempId, setTempId] = useState(null);

  const handleViewPopupClose = () => {
    setShowVeiwPopup(false);
  };
  const handleViewPopupShow = () => {
    setShowVeiwPopup(true);
  };

  // InspectionSteps;
  useEffect(() => {
    dispatch(getInspectionBookmark(id));
    dispatch(getInspectionStepsApi({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stateData = useSelector((state) => state.inspection);
  const inspStepsData = stateData.stepCategories;
  const inspBookmark = stateData.book_mark;

  const handleInspectionStepData = (stepName, bookmark_id) => {
    if (stepName in inspStepsData) {
      dispatch(
        storeActiveInspectionStep({
          name: stepName,
          inspection_id: id,
          bookmark_id: bookmark_id,
        })
      );
    }
  };

  const handleInspectionDescription = () => {
    const config = {
      method: "post",
      url: "/v1/oauth/inspection/checkIfInspectionExists",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { client_id: clientId },
    };
    axios(config)
      .then((res) => {
        setDescription(res.data.template.paragraph_text);
        setDialogBoxStatus("desc");
        handleViewPopupShow();
      })
      .catch((err) => console.log(err));
  };

  const SaveDescriptionChanges = (descText) => {
    const config = {
      method: "post",
      url: "/v1/oauth/inspection/create",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        client_id: clientId,
        template_id: tempId,
        paragraph_text: descText,
      },
    };
    axios(config)
      .then((res) => {
        handleViewPopupClose();
        const origin = window.location.origin;
        const link = origin + `/inspection-review/${id}/${clientId}`;

        const a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("target", "_blank");
        a.click();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    var getTempId = {
      method: "post",
      url: "/v1/oauth/inspection/checkIfInspectionExists",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { client_id: clientId },
    };
    axios(getTempId)
      .then((res) => {
        setTempId(res.data.template.id);
      })
      .catch((err) => console.log(err));
  }, [clientId, token]);
  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <div className="side-spacing pt50 ">
          <button
            type="button"
            className="float-start no-shadow pt-1 btn mr20"
            onClick={() => history.push("/clients")}>
            <img
              src="/assets/arrow-fwd-lg.png"
              style={{ transform: "rotate(180deg)" }}
              alt=""
            />
          </button>
          <div className="d-flex align-items-center justify-content-between ml20">
            <h2 className="mb-0">Create Inspection</h2>
          </div>
          <br />

          <Row className="mt20 inspStepsCt">
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("portfolio", "1")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 1 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1835.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.portfolio ? (
                      "Structure"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("roofing", "2")}>
                <Card.Body className="text-center  d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    <div>
                      {inspBookmark === 2 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}>
                          <img
                            src="/assets/icon/bookmark.png"
                            alt="active mark"
                          />
                        </div>
                      )}
                      <img
                        src="/assets/inspectionSteps/Group 1851.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.roofing ? (
                      "Roofing"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("exterior", "3")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between ">
                  <div className="inspStepImgCt">
                    <div>
                      {inspBookmark === 3 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}>
                          <img
                            src="/assets/icon/bookmark.png"
                            alt="active mark"
                          />
                        </div>
                      )}
                      <img
                        src="/assets/inspectionSteps/Group 1852.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.exterior ? (
                      "Exterior"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("electrical", "4")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 4 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/electrical.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.electrical ? (
                      "Electrical"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("heating", "5")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 5 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/heating.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.heating ? (
                      "Heating"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("cooling", "6")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 6 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1853.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.cooling ? (
                      "Cooling"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("plumbing", "7")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 7 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/pipeline.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.plumbing ? (
                      "Plumbing"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("interior", "8")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 8 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1854.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.interior ? (
                      "Interior"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("appliance", "9")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 9 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1855.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.appliance ? (
                      "Appliance"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("fireplaces", "10")}>
                <Card.Body className="text-center d-flex flex-column justify-content-between">
                  <div className="inspStepImgCt">
                    {inspBookmark === 10 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1856.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.fireplaces ? (
                      "Fireplaces"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom mb-4 cursor-pointer "}
                onClick={() => handleInspectionStepData("insulation", "12")}>
                <Card.Body className="text-center d-flex flex-column">
                  <div className="inspStepImgCt">
                    {inspBookmark === 11 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img
                          src="/assets/icon/bookmark.png"
                          alt="active mark"
                        />
                      </div>
                    )}
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1857.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 className="mt-2">
                    {" "}
                    {inspStepsData.insulation ? (
                      "Insulation"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} lg={3}>
              <Card
                className={"card-custom cursor-pointer "}
                onClick={handleInspectionDescription}>
                <Card.Body className="text-center d-flex flex-column">
                  <div className="inspStepImgCt">
                    <div>
                      <img
                        src="/assets/inspectionSteps/Group 1858.png"
                        className="menuCard-img"
                        alt="myAcc"
                      />
                    </div>
                  </div>
                  <h6 style={{ fontSize: 13 }}>
                    {inspStepsData.insulation ? (
                      "Overall Property Description"
                    ) : (
                      <Skeleton height="25px" width="100px" />
                    )}
                  </h6>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {showVeiwPopup && (
        <DialogBox
          handleClose={handleViewPopupClose}
          show={showVeiwPopup}
          data={description}
          title="Property Description"
          status={dialogBoxStatus}
          handleDescription={SaveDescriptionChanges}
        />
      )}
    </>
  );
};

export default InspectionSteps;
