import React, { useEffect, useState } from "react";

import { Row, Modal, Button, Col, Card } from "react-bootstrap";
import ScrollToTop from "../../../ScrollToTop";
import NavbarDashboard from "../../NavbarDashboard";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { setType,setTitle } from "../../../../redux/reducers/typeSlice";
const TemplateDetails = () => {
  const [show, setShow] = useState(false);
  const templateName = useSelector(state => state.type.templateName);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [tempUpdate, setTempUpdate] = useState(false);
  const history = useHistory();
  const [categories, setCategories] = useState([]); // State to manage categories
  const [selectedIcon, setSelectedIcon] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the category being edited
  const { id } = useParams();
  let token = localStorage.getItem("access_token");

  const handleClose = () => {
    setShow(false);
    setEditingIndex(null); // Reset editing index when closing modal
  };
  const handleShow = () => setShow(true);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };
  const handleIconSelect = (event) => {
    setSelectedIcon(event.target.value); // Update the selected icon state
  };
  const handleCategoryTypeSelect = (type) => {
    setCategoryType(type);
  };

  const fetchCategories = async () => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-list",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: id, // Use the template ID from the URL parameters
      },
    };

    try {
      const response = await axios(config);
      setCategories(response.data.data); // Assuming the response has a categories array
      console.log("Categories fetched successfully:", response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error.response || error);
    }
  };

  useEffect(() => {
    fetchCategories(); // Fetch categories when the component mounts
  }, [tempUpdate]);

  const createTemplateStep = async (templateId, title, icon, type) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step/create",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: templateId,
        title: title,
        icon: icon,
        optional_or_include: type, // Assuming 'type' is either 'included' or 'optional'
      },
    };

    try {
      const response = await axios(config);
      console.log("Template step created successfully:", response.data);
      setTempUpdate(!tempUpdate); // Update the tempUpdate state to trigger a re-fetch of
      // Handle success response, maybe update state or show success message
    } catch (error) {
      console.error("Error creating template step:", error.response || error);
      // Handle error, maybe show error message to the user
    }
    setCategoryName("");
    setCategoryType("");
    setSelectedIcon("");
    handleClose();
  };

  const startEditCategory = (index, id) => {
    const category = categories[index];
    setEditingIndex(index); // Set the index of the category being edited
    setCategoryName(category.title); // Assuming the category object has a title property
    setCategoryType(category.type === "optional" ? "option1" : "option2"); // Adjust based on your actual logic
    setSelectedIcon(category.icon); // Assuming the category object has an icon property
    handleShow(); // Open the modal for editing
  };

  // Step 1: Create the update function
  const updateTemplateStep = async (
    step_id,
    title,
    icon,
    type,
    template_id
  ) => {
    const config = {
      method: "post", // Make sure to use the correct HTTP method for your backend
      url: "/v1/oauth/templates/step/update",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: template_id, // Your existing logic to get the template ID
        template_step_id: step_id, // Assuming you need to pass the step's ID for the update
        title: title,
        icon: icon,
        optional_or_include: type,
      },
    };

    try {
      const response = await axios(config);
      console.log("Template step updated successfully:", response.data);
      setTempUpdate(!tempUpdate); // Trigger a re-fetch of the categories
      // Handle success response, maybe update state or show success message
    } catch (error) {
      console.error("Error updating template step:", error.response || error);
      // Handle error, maybe show error message to the user
    }
  };

  // Step 2: Modify the addCategory function to decide between creating or updating based on editingIndex
  const addOrUpdateCategory = () => {
    const newCategory = {
      name: categoryName,
      type: categoryType,
      icon: selectedIcon,
    };

    const templateId = id;
    const title = newCategory.name;
    const icon = newCategory.icon;
    const type = newCategory.type === "option1" ? "optional" : "included";

    if (editingIndex !== null) {
      const step_id = categories[editingIndex].id;
      const template_Id = id; // Your existing logic to get the template ID
      // Assuming each category has an id property
      updateTemplateStep(step_id, title, icon, type, template_Id);
    } else {
      // If editingIndex is null, create a new category
      createTemplateStep(templateId, title, icon, type);
    }

    // Reset form and close modal
    setCategoryName("");
    setCategoryType("");
    setSelectedIcon("");
    handleClose();
  };

  const icons = [
    "Group 1851.png",
    "Group 1852.png",
    "Group 1853.png",
    "Group 1854.png",
    "Group 1855.png",
  ];

  console.log(categories);

  const deleteCategory = async (step_id) => {
    const config = {
      method: "post", // Adjust if your API requires a different method like DELETE
      url: "/v1/oauth/templates/step/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_step_id: step_id,
        template_id: id, // Your existing logic to get the template ID
      },
    };

    try {
      const response = await axios(config);
      console.log("Category deleted successfully:", response.data);
      setTempUpdate(!tempUpdate); // Trigger a re-fetch or update the state to reflect the deletion
    } catch (error) {
      console.error("Error deleting category:", error.response || error);
    }
  };

  const dispatch = useDispatch();

  const handleLinkClick = (type,title) => {
    // Dispatch the setType action with the type as payload
    dispatch(setType(type));
    dispatch(setTitle(title));
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50">
          <div className="mb20 list-header border-bottom">
            <div className="float-start d-flex justify-content-center align-items-center">
              <img
                src="/assets/arrow-fwd-lg.png"
                style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                alt=""
                onClick={() => history.goBack()}
              />
              <h2 className="mt-2 ml10">{templateName || "Category" }</h2>
            </div>
            <div
              className="position-relative float-end search-list d-flex align-items-center justify-content-center mt-2 btn"
              onClick={handleShow}>
              <img
                src="/assets/icon/addicon.png"
                className="ml20 cursor-pointer mr10"
                alt=""
              />
              <div>Add Category</div>
            </div>
          </div>
          {categories.map((data, index) => (
            <Col sm={4} lg={3} key={index}>
              <Link
                to={`/template-steps/${id}/${data.id}`}
                style={{ textDecoration: "none", color: "black" }}
                onClick={() => handleLinkClick(data.optional_or_include,data.title)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}>
                <Card className="card-custom mb-4 cursor-pointer">
                  <Card.Body className="text-center d-flex flex-column justify-content-between">
                    <div className="inspStepImgCt">
                      <div className="position-relative">
                        <img
                          src={`/assets/inspectionSteps/${data.icon}`}
                          className="menuCard-img"
                          alt={data.name}
                        />
                        {hoveredIndex === index && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-26px",
                              right: "-8px",
                              display: "flex",
                            }}>
                            <img
                              src="/assets/icon/editicon.png"
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                startEditCategory(index);
                              }}
                            />
                            <img
                              src="/assets/icon/delete.png"
                              alt="Delete"
                              style={{
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent navigation
                                e.stopPropagation(); // Stop event bubbling
                                deleteCategory(data.id);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <h6 className="mt-2">{data.title}</h6>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}>
          <Modal.Title>Add a New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <div className="form-group">
            <label htmlFor="categoryName">Category Name:</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              value={categoryName}
              onChange={handleCategoryNameChange}
              style={{ border: "none", borderBottom: "1px solid #CDCDCD" }}
            />
          </div>
          <div className="form-group my-2">
            <label htmlFor="categoryType">Optiona/Included</label>
            <select
              className="form-control position-relative"
              id="categoryType"
              value={categoryType}
              style={{ border: "none", borderBottom: "1px solid #CDCDCD" }}
              onChange={(e) => handleCategoryTypeSelect(e.target.value)}>
              <option value="">Select</option>
              <option value="option1">
                Optional -add on a per-report base
              </option>
              <option value="option2">Include in every report</option>
            </select>
            <img
              src="/assets/icon/angle-down.png"
              alt=""
              style={{ position: "absolute", right: "6%", top: "53%" }}
            />
          </div>
          <div className="form-group w-100 position-relative">
            <div className="form-group">
              <label htmlFor="iconSelect">Select Icon:</label>
              <select
                className="form-control"
                id="iconSelect"
                style={{ border: "none", borderBottom: "1px solid #CDCDCD" }}
                onChange={handleIconSelect}>
                <option value="">Select Icon</option>
                {icons.map((icon, index) => (
                  <option key={index} value={icon}>
                    {icon}
                  </option>
                ))}
              </select>
              <img
                src="/assets/icon/angle-down.png"
                alt=""
                style={{ position: "absolute", right: "2%", top: "60%" }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            className="m-0 w-25 btn-submit mx-auto "
            variant="primary"
            onClick={addOrUpdateCategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateDetails;
