import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import {
  generateReport,
  inspectionExistence,
} from "../../../redux/reducers/inspectionSlice";
import DialogBox from "../../../utils/DialogBox";
import { useSelector } from "react-redux";
// import { RegisterBeams } from "../../../utils/utills";
import platform from 'platform'
import { getDatabase, ref, set } from 'firebase/database'
import * as PusherPushNotifications from '@pusher/push-notifications-web'

export var BeamsClientInstance;

const Reports = (props) => {
  const history = useHistory();
  const db = getDatabase()
  // const beamsClient = RegisterBeams()

  const userStoredData = localStorage.getItem("userData");
  const userStoredDataParsed = JSON.parse(userStoredData);
  const accountEmail = userStoredDataParsed.email;

  const inspectionexistForPdf = useSelector(
    (state) => state.inspection.inspectionexistForPdf
  );

  const [clients, setClients] = useState([]);
  const appA = false;
  const appB = false;
  const [selectedClientId, setSelectedClientId] = useState(
    props.location?.client_id ? props.location?.client_id : "Select Client"
  );
  const [clientCoverImage, setClientCoverImage] = useState();
  const [sendPicture, setSendPicture] = useState();
  const [logo, setLogo] = useState(true);
  const [newClientCoverImg, setNewClientCoverImg] = useState();
  const [sendToForm, setSendToForm] = useState({});

  const [show, setShow] = useState(false);
  const [titleForDialogBox, setTitleForDialogBox] = useState("");
  const [dialogBoxStatus, setDialogBoxStatus] = useState("");
  const [weatherDetails, setWeatherDetails] = useState({});

  const generateReportRef = useRef()
  const emailReportRef = useRef()
  const beamsRef = useRef()

  const [userPlatForm, setUserPlatform] = useState({
    browserPlatform: platform.name,
    userpermission: '',
  })
  let browserPlatform = platform.name
  console.log("outside os", userPlatForm.browserPlatform)

  if (generateReportRef.current) {
    generateReportRef.current.addEventListener('click', () => {
      console.log("inside os", userPlatForm.browserPlatform)
      if (userPlatForm.browserPlatform !== 'Chrome') {
        return
      }
      RegisterWithBeamsNotification()
      CheckIfBrowserSupportNotificationApi()
    })
    emailReportRef.current.addEventListener('click', () => {
      if (userPlatForm.browserPlatform !== 'Chrome') {
        return
      }
      CheckIfBrowserSupportNotificationApi()
      RegisterWithBeamsNotification()

    })
  }

  function CheckIfBrowserSupportNotificationApi() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      setUserPlatform({ ...userPlatForm, userpermission: 'granted' })
    } else if (Notification.permission !== "denied") {
      setUserPlatform({ ...userPlatForm, userpermission: 'denied' })
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setUserPlatform({ ...userPlatForm, userpermission: 'granted' })

        }
      });
    }
  }
  useEffect(() => {
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/service-worker.js');
    // }

    const permissionStatus = Notification.permission
    if (permissionStatus !== "granted") {
      console.log("reuest per")
      Notification.requestPermission()
    }
  }, [])
  // BeamsClientInstance =
  // window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
  //     serviceWorkerRegistration: serviceWorkerRegistration,
  //   })
  //   console.log("beams", BeamsClientInstance)
  const beams = new PusherPushNotifications.Client({
    instanceId: process.env.REACT_APP_BEAMS_INSTANCE_ID,
  })

  function RegisterWithBeamsNotification() {
    // console.log(BeamsClientInstance)
    // const userId = JSON.parse(localStorage.getItem('userData')).id
    // if (beams) {
    // console.log(userId)
    // beams.start().then(() => beams.addDeviceInterest('hello112')).then(() => console.log('Successfully registered and subscribed with prons-1111')).catch(console.error)
    // }
  }

  function beamsRegistration() {
    beams.start().then(() => beams.addDeviceInterest('hello112')).then(() => console.log('Successfully registered and subscribed with prons-1111')).catch(console.error)
  }

  if (beamsRef.current) {
    beamsRef.current.addEventListener('click', function () {
      beams.start().then(() => beams.addDeviceInterest('hello112')).then(() => console.log('Successfully registered and subscribed with prons-1111')).catch(console.error)
    })
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  // window.addEventListener('load', e => {
  //   if (!('serviceWorker' in navigator)) {
  //     console.log('Service worker not supported');
  //     return;
  //   }
  //   navigator.serviceWorker.register('/firebase-messaging-sw.js', {
  //     updateViaCache: 'none'
  //   })
  //     .then((registration) => {
  //       registration.addEventListener("updatefound", () => {
  //         // If updatefound is fired, it means that there's
  //         // a new service worker being installed.
  //         console.log(`Value of updateViaCache: ${registration.updateViaCache}`);
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(`Service worker registration failed: ${error}`);
  //     });
  // });

  if (inspectionexistForPdf === false) {
    notify("Inspection does not exist!");
  }
  var token = localStorage.getItem("access_token");

  const getClientsApi = async () => {
    document.body.classList.add("loading-indicator");

    var getUser = {
      method: "post",
      url: "/v1/oauth/client/get-clients-list",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { active: 1 },
    };

    await axios(getUser)
      .then((response) => {
        // console.log(response.data, 'response.data');
        if (response.data.status === true) {
          setClients(response.data.clients);
        }
        document.body.classList.remove("loading-indicator");
      })
      .catch((error) => {
        document.body.classList.remove("loading-indicator");

        if (error?.response?.status === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        }
      });
  };
  useEffect(() => {
    getClientsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.location.client_id && clients) {
      for (const client of clients) {
        if (client.id === parseInt(props.location.client_id)) {
          setNewClientCoverImg("");
          setClientCoverImage(client.pagecover);
          setSendToForm({
            email_to: client.email,
            email_from: accountEmail,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  const handleSelectClient = (value) => {
    var regex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i
    for (const client of clients) {
      if (client.id === parseInt(value)) {
        setNewClientCoverImg("")
        if (client.pagecover.search(regex) !== -1) {
          setSelectedClientId(client.id)
          setClientCoverImage(client.pagecover)
        } else {
          setSelectedClientId(client.id);
          setClientCoverImage('');

        }
        setSendToForm({
          email_to: client.email,
          email_from: accountEmail,
        });
      }
    }
  };

  const handleGenerateReport = () => {
    const fcm_token = localStorage.getItem("fcm_token")
    if (selectedClientId === "Select Client") {
      notify("Please select client!");
    } else {
      const inspection_report = clients.find(client => client.id === selectedClientId)
      if (inspection_report.inspection_report === undefined || inspection_report.inspection === null) {
        notify('Inspection does not exist!')
        return
      }
      dispatch(generateReport({ appA, appB, selectedClientId, fcm_token, notify, browserPlatform }));
    }
  };

  const handlePageCover = (e) => {
    if (selectedClientId !== "Select Client") {
      if (e.target.files[0]) {
        // console.log('we in e.target.files[0]')
        setSendPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setClientCoverImage("");
          setNewClientCoverImg(reader.result);
          setLogo(false);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      notify("Please select client!");
    }
  };
  const handleUpdateCover = () => {
    // console.log(pageCover, selectedClientId, 'we here in the handle Cover fucntion')
    if (selectedClientId === "Select Client") {
      notify("Please select client!");
    } else {
      document.body.classList.add("loading-indicator");
      var formData = new FormData();
      formData.append("client_id", selectedClientId);
      formData.append("pagecover", sendPicture);

      const configData = {
        method: "post",
        url: "/v1/oauth/inspection/update-inspection-cover",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      };
      axios(configData)
        .then((res) => {
          if (res.data.status === true) {
            document.body.classList.remove("loading-indicator");
            getClientsApi();
            notify(res.data.message, "success");
          }
        })
        .catch((err) => {
          document.body.classList.remove("loading-indicator");

          notify("Something went wrong!");
        });
    }
  };

  const handleEmailReportPopUp = (title) => {
    if (selectedClientId !== "Select Client") {
      const inspection_report = clients.find(client => client.id === selectedClientId)
      if (inspection_report.inspection_report === undefined || inspection_report.inspection === null) {
        notify('Inspection does not exist!')
        return
      }
      setTitleForDialogBox(title);
      setDialogBoxStatus("emailReport");
      handleShow();
    } else {
      notify("Please select client!");
    }
  };

  const handleEmailReport = (data) => {
    const fcm_token = localStorage.getItem("fcm_token")
    var cc = data.ccEmails
    var bcc = data.BccEmails

    const reference = ref(db, 'customers/' + selectedClientId)
    set(reference, {
      platform: userPlatForm.browserPlatform,
      permission: userPlatForm.userpermission
    })

    const formData = new FormData();
    formData.append("client_id", selectedClientId);
    formData.append("appendixA", appA);
    formData.append("appendixB", appB);
    formData.append("email_description", data.emailText);
    formData.append("mail_to", data.emailTo);
    formData.append("mail_from", data.emailFrom);
    formData.append("platform", 'web')
    if (userPlatForm.browserPlatform === "Chrome") {
      formData.append("os", "safari")
    } else {
      formData.append('fcm_token', fcm_token)
    }
    for (var i = 0; i < cc.length; i++) {
      formData.append("cc[]", data.ccEmails[i]);
    }
    for (var j = 0; j < bcc.length; j++) {
      formData.append("bcc[]", data.BccEmails[j]);
    }
    formData.append("cover", clientCoverImage);

    document.body.classList.add("loading-indicator");

    const config = {
      method: "post",
      url: "/v1/oauth/user/email-report",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(config)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
          notify(res.data.message, "success");
          handleClose();
        }
        if (res.data.status === false) {
          document.body.classList.remove("loading-indicator");
          notify(res.data.message);
          handleClose();

        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
        notify("Network Error!");
      });
  };

  const handleWeatherDetailsPopup = (title) => {
    if (selectedClientId !== "Select Client") {
      const inspection_report = clients.find(client => client.id === selectedClientId)
      if (inspection_report.inspection_report === undefined || inspection_report.inspection === null) {
        notify('Inspection does not exist!')
        return
      }
      setTitleForDialogBox(title);
      setDialogBoxStatus("weatherDetails");
      document.body.classList.add("loading-indicator");

      const configForm = {
        method: "get",
        url: "/v1/oauth/inspection/get-inspection-time",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { client_id: selectedClientId },
      };
      axios(configForm)
        .then((res) => {
          if (res.data.status === true) {
            setWeatherDetails(res.data.inspection);
            document.body.classList.remove("loading-indicator");
            handleShow();
          } else if (res.data.status === false) {
            document.body.classList.remove("loading-indicator");
            notify(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err, 'Error of Weather Details');
          document.body.classList.remove("loading-indicator");
          notify("Network Error!");
          if (err?.response?.status === 401) {
            window.location = "/login";
          }
        });
    } else {
      notify("Please select client!");
    }
  };
  const handleWeatherUpdationApi = (data) => {
    const weatherVals = {
      client_id: selectedClientId,
      start_date: formatDate(data?.startDate),
      end_date: formatDate(data?.endDate),
      weather_condition: data?.weatherConditions,
      start_time: data?.startTime.toLocaleTimeString(),
      end_time: data?.endTime.toLocaleTimeString(),
    };
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    const configForm = {
      method: "post",
      url: "/v1/oauth/inspection/update-inspection-time",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: weatherVals,
    };
    axios(configForm)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          notify("Weather Details Updated!", "success");
        } else {
          notify(res.data.message);
        }
      })
      .catch((err) => {
        notify("Network Error!");
        if (err?.response?.status === 401) {
          window.location = "/login";
        }
      });
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />

      <div className="content-container Mcontent">
        <div className="side-spacing pt50 ">
          <h2 className="mb50 ">Reports</h2>
          <div className="rep-dropdown-ct">
            <select
              value={selectedClientId}
              onChange={(e) => handleSelectClient(e.target.value)}
            >
              <option disabled>Select Client</option>
              {clients
                ? clients.map((client) => (
                  <option value={client.id} key={client.id}>
                    {client.client_name}
                  </option>
                ))
                : ""}
            </select>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}><label className="cursor-pointer">
            <input
              type="checkbox"
              // checked={true}
              // defaultChecked={true}
              className="mr10 form-check-input"
            // onClick={handleIfApplicable}
            />
            Include e-signature
          </label></div>
          <Row>
            <Col md={6}>
              <div className="choose-file-box">
                <div className="">
                  {clientCoverImage && (
                    <img src={clientCoverImage} alt="" className="add-image" />
                  )}
                  {!clientCoverImage && !newClientCoverImg && (
                    <img
                      src="/assets/add-image.png"
                      alt=""
                      className="add-image-logo"
                    />
                  )}
                  {newClientCoverImg && (
                    <img src={newClientCoverImg} alt="" className="add-image" />
                  )}
                </div>{" "}
                <br />
                <label className="w-100 custom-file-input">
                  <input
                    type="file"
                    name="pagecover"
                    onInputCapture={(e) => handlePageCover(e)}
                  />
                  <div className="btn-submit choose-file br4 text-black fs14">
                    Choose file
                  </div>
                </label>
                <br />
                <button
                  className="btn fs12 mt-2"
                  disabled={logo}
                  onClick={handleUpdateCover}
                >
                  Update
                </button>
              </div>
              <div className="text-center mt-3">
                <small className="text-black-50 fs10">
                  (image dimensions must be 225*170)
                </small>
              </div>
            </Col>
            <Col style={{ marginTop: 30 }} md={6}>
              <div>
                <button
                  className="btn-darkgrey w-100 p5 br4"
                  onClick={() => handleWeatherDetailsPopup("Weather Details")}
                >
                  Add Weather Details
                </button>
              </div>
              <div>
                <button
                  ref={generateReportRef}
                  className="btn-submit w-100 p5 br4"
                  onClick={() => handleGenerateReport()}
                >
                  Generate Report
                </button>
              </div>
              <div>
                <button
                  className="btn-submit w-100 p5 br4"
                  onClick={() => {
                    if (selectedClientId === "Select Client") {
                      notify("Please select client!");
                    } else {
                      const inspection_report = clients.find(client => client.id === selectedClientId)
                      if (inspection_report.inspection_report === undefined || inspection_report.inspection === null) {
                        notify('Inspection does not exist!')
                        return
                      }
                      dispatch(
                        inspectionExistence({
                          client_id: selectedClientId,
                          history,
                          from_review_inspection: true,
                        })
                      )
                    }
                  }
                  }
                >
                  Review Inspection
                </button>
              </div>
              <div>
                <button
                  ref={emailReportRef}
                  className="btn-submit w-100 p5 br4"
                  onClick={() => handleEmailReportPopUp("Email Report")}
                >
                  Email Report
                </button>
              </div>
              <div>
                <button
                  className="btn-submit w-100 p5 br4"
                  onClick={() => beamsRegistration()}
                >
                  Start Beams Function Call
                </button>
              </div>
              <div>
                <button
                  ref={beamsRef}
                  className="btn-submit w-100 p5 br4"
                >
                  Start Beams with ref
                </button>
              </div>
              <div>
                <button
                  className="btn-submit w-100 p5 br4"
                  onClick={() => {
                    const beamsinstance = new PusherPushNotifications.Client({
                      instanceId: process.env.REACT_APP_BEAMS_INSTANCE_ID,
                    })
                    beamsinstance.start().then(() => beamsinstance.addDeviceInterest('hello112')).then(() => console.log('Successfully registered and subscribed with prons-1111')).catch(err => { console.log("failed", err) })

                  }}
                >
                  Beams with inner function
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        // onClick={() => window.open(notificationLinkReport)}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {show && (
        <DialogBox
          show={show}
          title={titleForDialogBox}
          data={
            dialogBoxStatus === "weatherDetails" ? weatherDetails : sendToForm
          }
          handleWeatherUpdationApi={handleWeatherUpdationApi}
          handleClose={handleClose}
          status={dialogBoxStatus}
          handleEmailReport={handleEmailReport}
        />
      )}
    </>
  );
};

export default Reports;
