import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Row, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { setTemplateName } from "../../../redux/reducers/typeSlice";
import { useDispatch } from "react-redux";

const Templates = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const [updateInput, setUpdateInput] = useState("");
  const [updateTemp, setUpdateTemp] = useState(false);
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState(null); // Store the index of the template being edited

  const [input, setInput] = useState("");
  const [templates, setTemplates] = useState([]);
  let token = localStorage.getItem("access_token");

  function handleChange(e) {
    setInput(e.target.value);
  }

  useEffect(() => {
    const fetchTemplates = async () => {
      const config = {
        method: "get",
        url: "/v1/oauth/templates/list",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(config);
        setTemplates(response.data.data);
      } catch (error) {
        console.error("There was an error fetching the templates", error);
      }
    };

    fetchTemplates();
  }, [updateTemp]);
  console.log(templates);

  const handleSave = () => {
    if (input.trim()) {
      const title = {
        title: input,
      };

      const config = {
        method: "post",
        url: "/v1/oauth/templates/create",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: title,
      };

      // Make the POST request with the Authorization header
      axios(config)
        .then((response) => {
          console.log("Template created successfully", response.data);
          setUpdateTemp((Prev) => !Prev);
          setInput(""); // Clear the input field
          handleClose(); // Close the modal
        })
        .catch((error) => {
          console.error("There was an error creating the template", error);
        });
    }
  };

  const handleEdit = (id) => {
    const templateToEdit = templates.find((template) => template.id === id);
    if (templateToEdit) {
      setUpdateInput(templateToEdit.title);
      setEditIndex(id);
      setEditShow(true);
    }
  };

  const handleEditChange = (e) => {
    setUpdateInput(e.target.value);
  };

  const handleEditSave = () => {
    if (updateInput.trim() !== "") {
      const templateId = editIndex;

      const data = {
        template_id: templateId,
        title: updateInput,
      };

      const config = {
        method: "post",
        url: "/v1/oauth/templates/update",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          console.log("Template updated successfully", response.data);
          setUpdateTemp((prev) => !prev);
          setEditShow(false);
          setUpdateInput("");
        })
        .catch((error) => {
          console.error("There was an error updating the template", error);
        });
    }
  };

  const handleDelete = (id) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: id,
      },
    };
    axios(config)
      .then((response) => {
        console.log("Template deleted successfully", response.data);
        setUpdateTemp((prev) => !prev);
      })
      .catch((error) => {
        console.error("There was an error deleting the template", error);
      });
    setUpdateTemp((prev) => !prev);
  };
  const handleTitle = (title) => {
    dispatch(setTemplateName(title));
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50">
          <div className="mb20 list-header border-bottom">
            <h2 className=" float-start">My Templates</h2>
            <div
              className="position-relative float-end search-list d-flex align-items-center justify-content-center mt-2 btn"
              onClick={handleShow}>
              <img
                src="/assets/icon/addicon.png"
                className="ml20 cursor-pointer mr10"
                alt=""
              />
              <div>Add Template</div>
            </div>
          </div>

          {templates.map((data, index) => (
            <div
              key={data.id}
              className="d-flex justify-content-between align-items-center mb-2"
              style={{
                boxShadow: " 0px 0px 5.8px 0px rgba(0, 0, 0, 0.25)",
                padding: "16px",
                borderRadius: "8px",
              }}>
              <Link
                to={`/template-details/${data.id}`}
                style={{ textDecoration: "none", color: "black" }}
                onClick={() => handleTitle(data.title)}>
                <div>{data.title}</div>
              </Link>
              <div className="d-flex gap-2 ">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(data.id)}>
                  <img
                    src="/assets/icon/editicon.png"
                    style={{ cursor: "pointer" }}
                    alt=""
                    height={"20px"}
                    width={"20px"}
                  />{" "}
                </div>
                <div onClick={() => handleDelete(data.id)}>
                  <img
                    src="/assets/icon/delete.png"
                    style={{ cursor: "pointer" }}
                    alt=""
                    height={"20px"}
                    width={"20px"}
                  />
                </div>
              </div>
            </div>
          ))}
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}>
          <Modal.Title>Add a Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            placeholder="Enter Name"
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={(e) => handleChange(e)}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleSave}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* UPDATE MODAL */}

      <Modal show={editShow} onHide={handleEditClose} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}>
          <Modal.Title>Edit Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            placeholder="Enter Name"
            value={updateInput} // Use the updateInput state for the input value
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={handleEditChange}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleEditClose}>
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleEditSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Templates;
