import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";

const RadioCheckTemplate = (props) => {
  const { checked, title,template_id,step_id,id } = props.data;
  const { handleViewPopup,handleCheckChange,handleSubItemEdit,handleCategoryDeletion } = props;
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [showModal, setShowModal] = useState(false);
  const handleEye = () => {
    handleViewPopup();
    console.log("clicked eye");
  };


  const handleEditClick = () => {
    setShowModal(true); // Show the modal on edit click

  };
  const handleCategoryDelete = (template_id,step_id,id) => {
    handleCategoryDeletion(template_id,step_id,id);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = () => {
    handleSubItemEdit(props.data, updatedTitle); // Pass the updated title back
    setShowModal(false); // Close the modal
  };


  console.log(checked);

  return (
      <>
    <div className="d-flex align-items-center justify-content-between mb20">
      <Form.Check type="radio" name="group1" label={title} checked={checked}  onChange={()=> handleCheckChange(props.data)} />

      <div style={{ display: "flex" }}>
        <div className="cursor-pointer" style={{ marginRight: "10px" }} onClick={handleEditClick}>
          <img
            src="/assets/icon/editicon.png"
            alt=""
            style={{ height: 20, width: 20 }}
          />
        </div>

        <div
          className="cursor-pointer"
          style={{ marginRight: "10px" }}
          onClick={handleEye}>
          <img src="/assets/eye.png" alt="" style={{ height: 20, width: 20 }} />
        </div>

        <div className="cursor-pointer" onClick={()=>handleCategoryDelete(template_id,step_id,id )}>
          <img
            src="/assets/icon/delete.png"
            alt=""
            style={{ height: 20, width: 20 }}
          />
        </div>
      </div>

    </div>
        {/* Modal for editing the title */}
        <Modal show={showModal} onHide={handleCloseModal}className="p-3">
          <Modal.Header closeButton    className="my-2"
                        style={{ borderBottom: "none" }}>
            <Modal.Title>Edit Title</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4 w-100">


            <input
                type="text"
                value={updatedTitle}
                style={{
                  border: "none",
                  borderBottom: "1px solid #CDCDCD",
                  width: "100%",
                }}
                onChange={(e) => setUpdatedTitle(e.target.value)}
            />

          </Modal.Body>
          <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
            <Button     variant="secondary"
                        className="m-0 w-25 mx-2 " onClick={handleCloseModal}>
              Close
            </Button>
            <Button   className="m-0 w-25 btn-submit mx-2 "
                      variant="primary" onClick={handleSaveChanges}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default RadioCheckTemplate;
