import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useState } from "react";

const CategoryCheckTemplate = (props) => {
  const { checked, title, template_id, id, template_step_category_id } =
    props.data;
  const {
    handleSubItemEdit,
    handleCheckChange,
    handleCategoryDeletion,
    tabKey,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleCategoryDelete = (template_id, step_id, id) => {
    handleCategoryDeletion(template_id, step_id, id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = () => {
    handleSubItemEdit(props.data, updatedTitle);
    setShowModal(false);
  };
  const handleEye = () => {
    console.log("handle Eye");
  };
  console.log(tabKey);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb20 ">
        <Form.Check
          type="checkbox"
          id="default-checkbox"
          name="group1"
          className="custom-formCheck "
          label={title}
          checked={checked}
          onChange={() => handleCheckChange(props.data)}
        />

        <div className="d-flex mb-2">
          <div
            className="ml20 cursor-pointer"
            onClick={() => handleEditClick()}>
            <img src="/assets/icon/editicon.png" alt="" />
          </div>
          {tabKey === "recommendation" && (
            <div className="cursor-pointer ml10" onClick={handleEye}>
              <img
                src="/assets/eye.png"
                alt=""
                style={{ height: 20, width: 20 }}
              />
            </div>
          )}

          <div
            className="ml10 cursor-pointer"
            onClick={() =>
              handleCategoryDelete(template_id, template_step_category_id, id)
            }>
            <img
              src="/assets/icon/delete.png"
              style={{ height: 20, width: 20 }}
              alt=""
            />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}>
          <Modal.Title>Edit Title</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            value={updatedTitle}
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={(e) => setUpdatedTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleSaveChanges}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CategoryCheckTemplate;
